/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
// Local lib components
import { Row, Col, Visible } from 'react-grid-system';
// Local UI components
import PageLayout from '../shared/PageLayout';
import Profiles from '../page-components/Demande/Profiles';
import Seo from '../shared/Seo';
import SideMenu from '../shared/UIKit/SideMenu';
import SelectMenu from '../shared/UIKit/SelectMenu';

// Style

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Demande({ location }) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={t('title')} />
      <Visible xxl xl lg>
        <Row>
          <Col xl={3} lg={3} md={3}>
            <SideMenu pageName="Mes demandes" visitedPage={location.pathname} />
          </Col>
          <Col l={9} lg={9} md={9}>
            <Profiles />
          </Col>
        </Row>
      </Visible>
      <Visible md sm xs>
        <SelectMenu visitedPage="Mes demandes" />
        <Profiles />
      </Visible>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Demande", "Common", "Profile"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

Demande.propTypes = {
  location: PropTypes.string,
};
Demande.defaultProps = {
  location: {},
};
export default Demande;
