/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row } from 'react-grid-system';

// Local UI components
import Image from '../../../shared/Image';
import Loader from '../../../shared/UIKit/Loader';

// Helpers & utils
import { getToken } from '../../../shared/Services/auth';
import { baseUrl, baseUploads } from '../../../shared/APIs';

// Style
import './index.scss';

// assets
import iconred from '../../../images/shared-images/iconred.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Profiles() {
  /* ******************************** HOOKS ******************************* */
  // Localization
  const { t } = useTranslation();
  const filters = t('filters', {
    returnObjects: true,
  });
  const { status, nameth, scoreth, centreth, domaineth, dateth, statusth } = t(
    'profile',
    {
      returnObjects: true,
    },
  );

  const [requestList, setRequestList] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState();
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [isLoading, setIsLoading] = useState(false);

  function getRequests() {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/users-permissions/user/me/requests`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setRequestList(data.requests);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }

  function handleFilterChange(e) {
    const { name, value } = e.target;
    setAppliedFilters({ ...appliedFilters, [name]: value });
  }

  const populateFilters = useCallback(() => {
    requestList.forEach(({ profile: { id, center, jobTitle } }) => {
      setCurrentFilters((curFilters) => {
        const domainFilters = curFilters.domain;

        if (
          !domainFilters?.options?.find(({ value }) => {
            return value === jobTitle.toLowerCase().split(' ').join('-');
          })
        ) {
          domainFilters.options.push({
            id,
            value: jobTitle.toLowerCase().split(' ').join('-'),
            label: jobTitle,
          });
        }

        const centerFilters = curFilters.center;
        if (
          !centerFilters?.options?.find(
            ({ value }) =>
              value === center?.name.toLowerCase().split(' ').join('-'),
          )
        ) {
          centerFilters.options.push({
            id,
            value: center?.name.toLowerCase().split(' ').join('-'),
            label: center?.name,
          });
        }

        return {
          ...curFilters,
          domain: domainFilters,
          center: centerFilters,
        };
      });
    });
  }, [requestList]);

  function verifyDateFilter(filterValue, date) {
    if (
      filterValue === 'today' &&
      new Date().toDateString() !== new Date(date).toDateString()
    ) {
      return false;
    }

    const yearDiff = Math.abs(
      new Date(date).getFullYear() - new Date().getFullYear(),
    );
    const currMonth = new Date().getMonth() + 12;
    const month = new Date(date).getMonth() + 12;
    const monthDiff = currMonth - month;

    if (filterValue === 'this-month') {
      if (yearDiff !== 0) {
        return false;
      }

      if (monthDiff > 0) {
        return false;
      }
    }

    if (filterValue === 'last-month') {
      if (yearDiff > 1 || monthDiff > 1 || monthDiff === 0) {
        return false;
      }
    }

    if (filterValue === 'last-3-months') {
      if (yearDiff > 1 || monthDiff > 3 || monthDiff === 0) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    populateFilters();
  }, [populateFilters, requestList.length]);

  useEffect(() => {
    getRequests();
  }, []);
  /* ******************************** RENDERING ******************************* */

  function ListItems() {
    return requestList
      ?.filter(
        ({
          status: requestStatus,
          profile: { center, jobTitle },
          createdAt: date,
        }) => {
          if (!appliedFilters) return true;
          if (
            appliedFilters?.status &&
            appliedFilters?.status !== requestStatus &&
            appliedFilters?.status !== '*'
          ) {
            return false;
          }
          if (
            appliedFilters?.center &&
            appliedFilters?.center !==
              center?.name.toLowerCase().split(' ').join('-') &&
            appliedFilters?.center !== '*'
          ) {
            return false;
          }
          if (
            appliedFilters?.domain &&
            appliedFilters?.domain !==
              jobTitle.toLowerCase().split(' ').join('-') &&
            appliedFilters?.domain !== '*'
          ) {
            return false;
          }
          if (
            appliedFilters?.date &&
            verifyDateFilter(appliedFilters?.date, date) === false
          ) {
            return false;
          }
          return true;
        },
      )
      .map(
        ({
          id,
          profile: {
            profileName,
            score,
            center,
            jobTitle,
            profileImage: { url: imgUrl },
          },
          createdAt: date,
          status: requestStatus,
        }) => {
          return (
            <tr className="profiles-table-tr" key={id}>
              <td className="profiles-table-td">
                <div className="td-wrapper">
                  <img
                    className="profiles-table-td-img"
                    src={`${baseUploads}${imgUrl}`}
                    alt=""
                  />
                  <p className="profiles-table-td-p">{profileName}</p>
                </div>
              </td>
              <td className="profiles-table-td">{score}</td>
              <td className="profiles-table-td">{center.name}</td>
              <td className="profiles-table-td">{jobTitle}</td>
              <td className="profiles-table-td">
                {new Date(date).toLocaleDateString()}
              </td>
              <td className="profiles-table-td">
                <div className="td-wrapper">
                  <p className={status[requestStatus]?.classname}>
                    {status[requestStatus]?.value}
                  </p>
                  <Image src={status[requestStatus]?.statusIcon} alt="" />
                </div>
              </td>
            </tr>
          );
        },
      );
  }

  function Filters() {
    return Object.keys(currentFilters)?.map((key) => {
      const currFilter = currentFilters[key];
      if (key === 'title') return null;
      return (
        <div key={key} className="filter">
          <span className="filter__title">{currFilter?.title}</span>
          <select
            name={key}
            id={key}
            value={
              appliedFilters
                ? appliedFilters[key]
                : currFilter?.options[0].value
            }
            onChange={handleFilterChange}
          >
            {currFilter?.options?.map(({ id, value, label }) => (
              <option key={id} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      );
    });
  }

  return (
    <Container fluid>
      <img className="imgProfil" src={iconred} alt="Elife" />

      <Container className="profiles">
        <Row>
          <p className="profil-filter-title">{filters.title}</p>
          <div className="filters">
            <Filters />
          </div>
        </Row>
        <Row className="title-section">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="table-wrapper">
              <table className="profiles-table">
                <tr className="profiles-table-th">
                  <th className="profiles-table-td1">{nameth}</th>
                  <th className="profiles-table-td1">{scoreth}</th>
                  <th className="profiles-table-td1">{centreth}</th>
                  <th className="profiles-table-td1">{domaineth}</th>
                  <th className="profiles-table-td1">{dateth}</th>
                  <th className="profiles-table-td1">{statusth}</th>
                </tr>
                <ListItems />
              </table>
            </div>
          )}
        </Row>
      </Container>
    </Container>
  );
}

Profiles.propTypes = {};

Profiles.defaultProps = {};

export default Profiles;
